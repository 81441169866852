<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการสมาชิก
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mx-auto mb-1">
            <b-col cols="12 col-md-10" class="pr-1 pl-1 mb-1">
              <div>
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่สมัคร (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่สมัคร (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="status"> สถานะ </span>
                  </b-input-group-prepend>
                  <v-select v-model="selected" :options="option" label="title" combobox-class="form-control" style="width: 200px">
                    <template #option="{ title }">
                      {{ title }}
                    </template>
                  </v-select>

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-12 col-lg-2">
              <div class="btn-group">
                <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light"> ค้นหา </b-button>
                <b-button v-b-toggle.sidebar-right variant="btn-primary" class="btn bg-gradient-info text-nowrap waves-effect waves-light"> สมัครสมาชิก </b-button>
              </div>
              <b-sidebar
                id="sidebar-right"
                bg-variant="white"
                right
                no-header
                backdrop
                shadow
              >
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                  <h5 class="mb-0"> สมัครสมาชิก </h5>
                  <feather-icon icon="XIcon" class="cursor-pointer font-medium-1 ml-1" @click="close_register()" v-b-toggle.sidebar-right />
                </div>
                <span>
                <validation-observer ref="createUser">
                  <form class="p-2">
                    <span>
                      <b-form-group>
                        <label class="control-label">ชื่อ-นามสกุล</label>
                        <validation-provider
                          #default="{ errors }"
                          name="ชื่อ-นามสกุล"
                          rules="required"
                          ref="full_name"
                        >
                          <b-form-input v-model="register.full_name" :state="errors.length > 0 ? false:null" type="text" placeholder="ข้อมูล ชื่อ-นามสกุล" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span>
                      <b-form-group>
                        <label class="control-label">ชื่อบัญชี</label>
                        <validation-provider
                          #default="{ errors }"
                          name="ชื่อบัญชี"
                          rules="required"
                          ref="bank_name"
                        >
                          <b-form-input v-model="register.full_name" :state="errors.length > 0 ? false:null" type="text" placeholder="ข้อมูล ชื่อบัญชี" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span>
                      <b-form-group>
                        <label class="control-label">เลขบัญชี</label>
                        <validation-provider
                          #default="{ errors }"
                          name="เลขบัญชี"
                          rules="required"
                          ref="bank_number"
                        >
                          <b-form-input v-model="register.bank_number" :state="errors.length > 0 ? false:null" type="text" placeholder="ข้อมูล เลขบัญชี" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span>
                      <b-form-group>
                        <label class="control-label">ธนาคาร</label>
                        <validation-provider
                          #default="{ errors }"
                          name="ธนาคาร"
                          rules="required"
                          ref="bank"
                        >
                          <b-form-select v-model="register.bank" :state="errors.length > 0 ? false : null || register.bank == '0' ? false : null" :options="option_bank"/>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span>
                      <b-form-group>
                        <label class="control-label">สถานะ</label>
                        <validation-provider
                          #default="{ errors }"
                          name="สถานะ"
                          rules="required"
                          ref="role"
                        >
                          <b-form-select v-model="register.role" :state="errors.length > 0 ? false:null" :options="option_role"/>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span>
                      <b-form-group>
                        <label class="control-label">เบอร์มือถือ</label>
                        <validation-provider
                          #default="{ errors }"
                          name="เบอร์มือถือ"
                          rules="required"
                          ref="phone"
                        >
                          <b-form-input v-model="register.phone" :state="errors.length > 0 ? false:null" type="text" placeholder="ข้อมูล เบอร์มือถือ" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span>
                      <b-form-group>
                        <label class="control-label">รหัสผ่าน Pin</label>
                        <validation-provider
                          #default="{ errors }"
                          name="รหัสผ่าน Pin"
                          rules="required"
                          ref="password_pin"
                        >
                          <b-form-input v-model="register.password_pin" :state="errors.length > 0 ? false:null" type="text" placeholder="ข้อมูล รหัสผ่าน Pin" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <span>
                      <b-form-group>
                        <label class="control-label">PREFIX</label>
                        <validation-provider
                          #default="{ errors }"
                          name="prefix"
                          rules="required"
                          ref="role"
                        >
                          <b-form-select v-model="register.prefix" :state="errors.length > 0 ? false:null" :options="option_prefix"/>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </span>
                    <div class="d-flex mt-2">
                      <b-button @click="user_register()" variant="btn-primary" class="btn bg-gradient-primary text-nowrap waves-effect waves-light mr-2">
                        สมัคร
                      </b-button>
                      <b-button @click="close_register()" v-b-toggle.sidebar-right variant="btn-primary" class="btn bg-gradient-danger text-nowrap waves-effect waves-light">
                        ยกเลิก
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
                </span>
              </b-sidebar>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1" />
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(bank)="data">
                  <ul class="users-list align-items-center m-0 p-0">
                    <li
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-placement="bottom"
                      :data-original-title="(typeof(bank_list[data.value]) != undefined ? bank_list[data.value] : '-')"
                      class="avatar pull-up m-0"
                    >
                      <img
                        class="media-object rounded"
                        :src="BASE_URL + 'assets/app-assets/images/bank/' + bank_admin[data.value] + '.png'"
                        alt="Bank"
                        height="40"
                        width="40"
                      >
                    </li>
                  </ul>
                </template>
                <template #cell(amount_wallet)="data">
                  {{ func.toMoney(data.value) }} ฿
                </template>
                <template #cell(account)="data">
                  <b-button v-if="data.item.username == ''" :data-id="data.item.id" :data-username="data.item.username" variant="btn-primary" class="btn bg-gradient-secondary waves-effect waves-light">
                    ยังไม่ได้รับยูสเซอร์<small class="d-block"> (กดเพื่อสร้างยูส)</small>
                  </b-button>
                  <b-button @click="modal_user" v-b-modal.modal-user v-if="data.item.username != ''" :data-id="data.item.id" :data-username="data.item.account_agent_username" :data-password="data.item.account_agent_password" variant="btn-primary" class="btn bg-gradient-warning waves-effect waves-light">
                    {{data.item.account_agent_username}}
                  </b-button>
                </template>
                <template #cell(manage)="data">
                  <div class="btn-group">
                    <b-dropdown variant="link" toggle-class="bg-gradient-success waves-effect waves-light">
                      <template #button-content>
                        <span><feather-icon icon="EditIcon" class="font-medium-1 mr-1" /></span>จัดการ
                      </template>
                      <b-dropdown-item :to="'/user/user_form_update/' + data.item.id"><span><feather-icon icon="EditIcon" class="text-warning mr-1" /></span>แก้ไข</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item :data-id="data.item.id" :data-username="data.item.username"><span><feather-icon icon="TrashIcon" class="text-danger mr-1" /></span>ลบ</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-modal
      id="modal-user"
      centered
      title="Username / Password"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-primary waves-effect waves-light"
    >
      <b-row>
        <b-col cols="md-6">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Username" v-model="username" />
          </b-input-group>
        </b-col>
        <b-col cols="md-6">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="LockIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Password" v-model="password" />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="md-6">
          <b-form-checkbox
            checked="true"
            class="custom-control-success mr-3"
            name="check-button"
            switch
            inline
          >
            ระงับ
          </b-form-checkbox>
          <b-form-checkbox
            checked="false"
            class="custom-control-danger"
            name="check-button"
            switch
            inline
          >
            ล็อค
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <ul class="list-group"> <li class="list-group-item active"><span class="font-weight-bold">MAIN WALLET</span> : <span class="float-right">{{ main_wallet }}</span></li></ul>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { BModal, VBModal, BAlert, BFormCheckbox, BSidebar, VBToggle  } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import func from '@core/comp-functions/misc/func'
import {
  required, email, confirmed, password,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard, BCardHeader, BCardBody, BSidebar,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton, BModal, BAlert, BFormCheckbox,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      main_wallet: '0',
      username: 'Username: ',
      password: 'Password: ',
      required,
      confirmed,
      register: {
        full_name: '',
        bank_number: '',
        bank: 0,
        role: 2,
        phone: '',
        password_pin: '',
        prefix: 'ADMIN',
      },
      cache_register: {
        full_name: '',
        bank_number: '',
        bank: 0,
        role: 2,
        phone: '',
        password_pin: '',
        prefix: 'ADMIN',
      },
      date_start_report: '',
      date_end_report: '',
      selected: { title: 'ทั้งหมด' },
      option: [
        { title: 'ทั้งหมด' },
        { title: 'ได้รับยูสเซอร์แล้ว' },
        { title: 'ยังไม่ได้รับยูสเซอร์' },
      ],
      option_prefix: [
        { text: 'เลือก prefix', disabled: true },
      ],
      option_role: [
        { text: 'เลือกสถานะ', disabled: true },
        { text: 'ผู้ดูแลระบบสูงสุด', value: '0' },
        { text: 'ผู้ดูแลระบบ', value: '1' },
        { text: 'สมาชิก', value: '2' },
        { text: 'พนักงาน', value: '3' },
      ],
      option_bank: [
        { text: 'เลือกธนาคาร', value: '0', disabled: true },
        { text: 'ธนาคารกรุงเทพ จำกัด (มหาชน)', value: '01' },
        { text: 'ธนาคารกสิกรไทย จำกัด (มหาชน)', value: '02' },
        { text: 'ธนาคารกรุงไทย จำกัด (มหาชน)', value: '03' },
        { text: 'ธนาคารทหารไทยธนชาต จำกัด (มหาชน)', value: '04' },
        { text: 'ธนาคารไทยพาณิชย์ จำกัด (มหาชน)', value: '05' },
        { text: 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)', value: '06' },
        { text: 'ธนาคารออมสิน จำกัด (มหาชน)', value: '07' },
        { text: 'ธนาคารธนชาติ จำกัด (มหาชน)', value: '08' },
        { text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร จำกัด (มหาชน)', value: '09' },
      ],
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      filter: '',
      filterOn: [],
      fields: [
        { key: 'phone', label: 'เบอร์มือถือ', class: 'text-center' },
        { key: 'full_name', label: 'ชื่อ - นามสกุล', class: 'text-center text-nowrap' },
        { key: 'bank', label: 'ธนาคาร', class: 'text-center' },
        { key: 'bank_number', label: 'เลขบัญชี', class: 'text-center' },
        { key: 'created_at', label: 'วันที่สมัคร', class: 'text-center text-nowrap' },
        { key: 'amount_wallet', label: 'Wallet Auto', class: 'text-center text-nowrap' },
        // { key: 'amount_wallet_ref', label: 'Wallet Commission', class: 'text-center' },
        { key: 'account', label: 'User / Password', class: 'text-center' },
        { key: 'manage', label: 'จัดการข้อมูล', class: 'text-center' },
      ],
      items: [],
      t_fetch: 0,
      bank_admin: {'01': '1', '02': '2', '03': '3', '04': '5', '05': '6', '06': '4', '07': '7', '08': '9', '09': 'baac'},
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.totalRows = this.items.length
    this.fetch()
    this.fetch_prefix()
    this.t_fetch = setInterval(() => {
      if (this.currentPage != 1) return;
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    close_register() {
      $('#sidebar-right').toggle();
      this.register = Object.assign(this.register, this.cache_register);
      this.$refs.full_name.reset();
      this.$refs.bank_name.reset();
      this.$refs.bank_number.reset();
      this.$refs.bank.reset();
      this.$refs.role.reset();
      this.$refs.phone.reset();
      this.$refs.password_pin.reset();
    },
    user_register() {
      this.$refs.createUser.validate().then(success => {
        if (!success) {
          return this.warn('กรุณากรอกข้อมูลให้ครบทุกช่อง')
        }
        this.loading()
        let url = `agent/create_user_save`;
        this.$http.post(url, this.register).then(res => {
          this.close_register()
          this.$swal.close()
          if (res.data.code != 0) {
            this.warn(res.data.message)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ทำรายการสำเร็จ',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
            this.fetch()
          }
        }).catch(()=>this.$swal.close())
      })
    },
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        willOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `user/user_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
    fetch_prefix() {
      this.$http.get('ref/prefix').then(res => { res.data.forEach(data=> this.option_prefix.push({text: data.prefix, value: data.prefix}))})
    },
    modal_user(e) {
      if (!e.target.dataset) return;
      this.username = e.target.dataset.username;
      this.password = e.target.dataset.password;
      let url = `user/remaining_credit_all/${e.target.dataset.id}`;
      this.$http.post(url).then(res => {
        if (res.data != null && res.data.result != null) this.main_wallet = res.data.result.MAIN.value;
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.vs__dropdown-toggle, .vs__dropdown-toggle:hover { border-top-left-radius: 0; border-bottom-left-radius: 0; }
</style>
